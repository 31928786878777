import { useEffect } from 'react'

import { useOrganization, useUser } from '@clerk/remix'

import { getLori4LoriPublicKey } from '../helpers/url'

const LORIKEET_USER_TOKEN_KEY = 'lori_user_token_v2'

function getSessionKey(pk: string = 'session') {
  return `${LORIKEET_USER_TOKEN_KEY}_${pk}`
}

export function useWidget() {
  const { isSignedIn } = useUser()

  // we don't get active organisation from useUser :(
  const { isLoaded, organization } = useOrganization()

  useEffect(() => {
    if (!(isSignedIn && isLoaded)) {
      return
    }

    if (window.lorikeet === undefined) {
      return
    }

    window.lorikeet.on('token_expiry', () => {
      console.log('token_expiry')
      localStorage.removeItem(getSessionKey(publicKey))
    })

    const publicKey = import.meta.env.PROD
      ? getLori4LoriPublicKey(organization?.id)
      : undefined

    const cachedToken = localStorage.getItem(getSessionKey(publicKey))
    if (cachedToken) {
      window.lorikeet.init({
        anonymous: true,
        // token: cachedToken,
        public_key: publicKey,
        customisations: {
          desktop: {
            default_open: false,
          },
        },
      })
      return
    }

    void fetch('/api/token')
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
        return null
      })
      .then((token) => {
        if (token) {
          localStorage.setItem(getSessionKey(publicKey), token)
          window.lorikeet.init({
            customisations: {
              desktop: {
                default_open: false,
              },
            },
            token,
            public_key: publicKey,
          })
        } else {
          window.lorikeet.init({
            customisations: {
              desktop: {
                default_open: false,
              },
            },
            public_key: publicKey,
          })
        }
      })
  }, [isSignedIn])
}
