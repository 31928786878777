export const AU_ONLY_SUBSCRIBER_IDS = [
  'c86118b7-d392-4192-aa4b-8f175e96f50b', // Lorikeet AU (Sandbox)
  'cf48bade-6734-47b4-a78b-c1a22350783f', // Lorikeet AU (Lori4Lori)
  'a2647536-6058-42bb-bc42-f2b104e63d80', // Kismet
  '2839b1e4-6dc6-4976-b798-7053705e9143', // Easykind
]

// I know, I don't like this either (not that I liked the AU_ONLY_SUBSCRIBER_IDS to begin with.)
// We need to know at widget loading time whether we are in AU or not.
export const AU_ONLY_CLERK_ORG_IDS = [
  'org_2ryInEoAhpmQNmJzcPmvdRWLwKx', // Lorikeet AU (Sandbox)
  'org_2svIOY4KXr2uHYgnzl0l6hpwIaf', // Lorikeet AU (Lori4Lori)
  'org_2omdWP5BSFTdS3H3lkoO9ma10nz', // Kismet
  'org_2q5ImmLy0N0VmODIa7O48c9K5kP', // Easykind
]

export const isAuSubscriber = (subscriberId: unknown) =>
  typeof subscriberId === 'string' &&
  AU_ONLY_SUBSCRIBER_IDS.includes(subscriberId)

export const isAuClerkOrg = (orgId: unknown) =>
  typeof orgId === 'string' && AU_ONLY_CLERK_ORG_IDS.includes(orgId)

export const getApiUrl = (subscriberId: unknown) =>
  isAuSubscriber(subscriberId) ? process.env.API_AU_URL : process.env.API_URL

export const getLori4LoriPublicKey = (orgId: unknown) =>
  isAuClerkOrg(orgId)
    ? 'wk_76615ecc-5d72-4c10-99b8-dec79ca4f815'
    : 'wk_a01eebcb-668b-4414-aadc-21fe7102c1e7'
